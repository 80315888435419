import { FC, useEffect, useState } from 'react';
import { getDatasourceEntries } from '../../../services/storyblok';
import { RadioField } from 'hult-toolkit';
import { DependantValueDefault } from '../QuestionInputHelpers';
import { IPicklist } from './PickList.types';
import { useStepContext } from '../../../context/StepContext';
import { useQuestionContext } from '../../../context/QuestionContext';
import { Input } from '../../ui/inputs/input';
import { StyledQuestionContainer } from '../../QuestionForm/questionForm.styles';

const emptySubmitObj = {
  value: '',
  valid: false,
  name: '',
};

export const PicklistInput: FC<IPicklist> = ({
  label,
  select_ds_options,
  select_blok_options,
  handleChange,
  dependentOn = '',
}) => {

  const { stepState } = useStepContext()
  const { questionState } = useQuestionContext()

  const dependantValue = stepState?.[dependentOn || ''];

  const defaultInputVal = questionState?.submitObj?.savedValue

  const defaultValue = typeof defaultInputVal === 'boolean' ? JSON.stringify(defaultInputVal) : defaultInputVal

  const defaultOptions =
    select_blok_options && select_blok_options.length > 0
      ? select_blok_options
      : [];

  const datasourceDependant = DependantValueDefault(
    dependentOn,
    dependantValue
  );

  const [options, setOptions] = useState(defaultOptions);
  const [outputObj, setOutputObj] = useState(emptySubmitObj);
  const [picklistLoaded, setPicklistLoaded] = useState<boolean>(false)

  useEffect(() => {
    if (select_ds_options) {
      getDatasourceEntries(select_ds_options, datasourceDependant)
        .then(result => {
          setOptions(result);
        })
        .then(() => {
          setPicklistLoaded(true)
        })
        .catch((error: any) => {
          console.error('getDatasourceEntries error', error);
        });
    } else {
      setOptions(select_blok_options || []);
    }
  }, [select_ds_options, select_blok_options]);

  useEffect(() => {
    const resValue = outputObj?.value;
    const allOptions = options.map(({ value }) => value.toLowerCase());
    const resValid = allOptions.includes(resValue.toLowerCase())

    handleChange({
      value: outputObj.value,
      valid: resValid,
      name: outputObj.name,
    });

  }, [options, outputObj]);

  const fieldOptions = options.map(({ value, label }) => ({ name: label, value }))

  if (!picklistLoaded && !options.length) {
    return (
      <StyledQuestionContainer>
        <Input type="text" label="Loading..." loading={true} />
      </StyledQuestionContainer>
    )
  }

  return (
    <RadioField
      type="radio"
      name={questionState.questionSlug}
      label={label}
      options={fieldOptions}
      onChange={(e: any) => setOutputObj(e)}
      default_value={defaultValue}
    />
  );
};
