import { useState, FC } from 'react';
import { useAppContext } from '../../context/AppContext';
import { useQuestionContext } from '../../context/QuestionContext';
import { useStepContext } from '../../context/StepContext';
import { useGetProgramStepDataQuery } from '../../graphql';
import { useNavigate } from 'react-router-dom';
import { questionProgramPayloadRender } from './steps.helpers';
import { IStep } from '../../data/types';
import { StyledQuestion } from './steps.styles';
import { LoadingInput } from '../QuestionInputs/TextInput/TextInput.helpers';
import QuestionsListProgram from '../QuestionsListProgram';
import { variantMapping } from '../../utils/variantMapping';
import { AlertSa } from 'hult-toolkit';
import { useSettingsContext } from '../../context/SettingsContext';
import { render } from 'storyblok-rich-text-react-renderer'

export const StepProgram: FC<IStep> = ({
  questions = [],
  isPreview,
}) => {

  const { questionDispatch } = useQuestionContext()
  const { stepDispatch } = useStepContext()
  const { selectedApplication } = useAppContext()
  const navigate = useNavigate()
  const { errorMessage } = useSettingsContext();

  const [isLoading, setIsLoading] = useState(true)

  const { error } = useGetProgramStepDataQuery({
    variables: {
      guid: selectedApplication || '',
    },
    fetchPolicy: 'cache-and-network',
    skip: isPreview || !selectedApplication || !isLoading,
    onCompleted({ programData, sessionData }) {

      const dispatchPayload = questionProgramPayloadRender({
        questions,
        programData,
        sessionData
      })

      stepDispatch({
        type: 'update-step-context',
        payload: programData
      })

      questionDispatch({
        type: 'update-question-context',
        payload: dispatchPayload
      })

      navigate(`/question?step=program&q=${dispatchPayload.questionSlug}`)

      setIsLoading(false)
    },
    onError(err) {
      console.log(err)
    }
  });

  if (isLoading) {
    return <LoadingInput />
  }

  if (error) {
    console.error(error);
    return (
      <StyledQuestion>
        {errorMessage && (
          <AlertSa
            desc={render(errorMessage.desc)}
            icon={variantMapping[errorMessage.variant].icon}
            icon_color={variantMapping[errorMessage?.variant].icon_color}
            title={errorMessage.title || ''}
          />
        )}
      </StyledQuestion>
    );
  }

  return (
    <StyledQuestion>
      <QuestionsListProgram error={error} loading={isLoading} />
    </StyledQuestion>
  );
};