import { QuestionFormButton } from '../QuestionForm/questionFormButton';
import { useStepContext } from '../../context/StepContext';
import { useQuestionContext } from '../../context/QuestionContext';
import { DateField, InputField, UrlField } from 'hult-toolkit';
import { StyledButtonContainer, StyledText } from '../QuestionForm/questionForm.styles';
import { IEvent } from '../QuestionForm/questionForm.types';
import { ISingleQuestion } from './SingleQuestion.types';

import { AddressInput } from '../QuestionInputs/AddressInput';
import { EmailInput } from '../QuestionInputs/EmailInput';
import { LookUpInput } from '../QuestionInputs/LookUpInput';
import { MultiLookUpInput } from '../QuestionInputs/MultiLookupInput';
import { PicklistInput } from '../QuestionInputs/PickListInput';
import { RecommendationInput } from '../QuestionInputs/RecommendationInput';
import { SchoolInput } from '../QuestionInputs/SchoolInput';

import { checkValueUpdated, convertDateFormat, dateMapping, renderValue, replacePlaceholders } from './SingleQuestion.helper';

export const SingleQuestion = ({
  question,
  saving,
  submitClick,
}: ISingleQuestion) => {
  const { stepState } = useStepContext();
  const { questionState, questionDispatch } = useQuestionContext();

  const { questionMapping, submitObj } = questionState

  const defaultVal = stepState[questionMapping] ?? ''

  const answerChange = (e: IEvent) => {

    const savedValue = submitObj.savedValue
  
    const hasUpdated = checkValueUpdated(e.value, savedValue, questionMapping)
    const resVal = renderValue(e.value)

    questionDispatch({
      type: 'update-submit-object',
      payload: {
        submitObj: {
          value: resVal,
          valid: e.valid,
          updated: hasUpdated
        }
      }
    })
  };

  const qContent = question.content

  const questionProps = {
    questionMapping,
    handleChange: answerChange,
  };

  const questionTypeRender = () => {

    const questionType = qContent?.question_type;

    switch (questionType) {

      case 'address':
        return <AddressInput {...questionProps} />;
      case 'date':

        const defaultDate = convertDateFormat(defaultVal)

        const { format, allowFuture } = dateMapping(questionMapping);
  
        return (
          <DateField
            default_value={defaultDate}
            name={question?.name}
            format={format}
            onChange={answerChange}
            required={qContent.required}
            allow_future={allowFuture}
          />
        )
      case 'email':
        return (
          <EmailInput
            handleChange={answerChange}
            required={qContent?.required}
          />
        );
      case 'linkedin':
        return (
          <UrlField
            url="https://linkedin.com/in/"
            name={''}
            onChange={answerChange}
            min_length={3}
            default_value={defaultVal}
          />
        )
      case 'lookup':
        return (
          <LookUpInput
            {...questionProps}
            datasource={qContent?.lookup_datasource}
            handleChange={answerChange}
          />
        );
      case 'number':
        return (
          <InputField
            default_value={defaultVal}
            onChange={answerChange}
            type="number"
            name="number"
            label=" "
            required={qContent.required}
            min_length={1}
          />
        )
      case 'multi_lookup':
        return (
          <MultiLookUpInput
            {...questionProps}
            datasource={qContent?.lookup_datasource}
          />
        );
      case 'picklist':
        return (
          <PicklistInput
            {...questionProps}
            select_ds_options={qContent?.select_ds_options}
            select_blok_options={qContent?.select_blok_options}
            dependentOn={qContent?.dependent_on}
          />
        );
      case 'recommendation':
        return <RecommendationInput {...questionProps} />;
      case 'school_lookup':
        return (
          <SchoolInput handleChange={answerChange} datasource={qContent?.lookup_datasource} />
        );
      case 'telephone':
        return (
          <InputField
            type="tel"
            label=" "
            name={questionMapping}
            default_value={defaultVal}
            required={qContent.required}
            onChange={answerChange}
            min_length={7}
          />
        )
      case 'text':
        return (
          <InputField
            type="text"
            label=" "
            placeholder=''
            name={questionMapping}
            onChange={answerChange}
            default_value={defaultVal}
            required={question.content.required}
            min_length={2}
          />
        )
      default:
        console.log(`INVALID INPUT: ${qContent?.question_type}`);
        return <></>;
    }
  };
  
  const disableButton = () => {
    const isRequired = question.content.required;
    const hasValue = questionState.submitObj.value;
    const isValid = questionState.submitObj.valid;
  
    if (!isRequired) {
      return hasValue && !isValid;
    }
  
    return !isValid;
  };

  return (
    <>
      <StyledText>
        <h2>{question.content?.display_title}</h2>
        <p>{replacePlaceholders(question.content?.description || '', stepState)}</p>
      </StyledText>
      <div className="input-wrapper">
        {questionTypeRender()}
      </div>
      <StyledButtonContainer>
        <QuestionFormButton
          label='Continue'
          saving={saving}
          onClick={submitClick}
          disabled={disableButton()}
        />
      </StyledButtonContainer>
    </>
  )
};