import { IPicklistDefault } from './PickListInput/PickList.types';
import { ICheckEmailValidity } from './QuestionInputs.types';

// check if url is a valid linkedInUrl
export function checkValidLinkedInUrl(str: string) {
  const linkedInStr = 'https://www.linkedin.com/in/';

  if (str === linkedInStr) {
    return undefined;
  }
  return /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9_-]{3,}\/?$/.test(
    str
  );
}

// checks if input is a valid phone number
export function checkValidNumber(str: string) {
  const telephoneRegex = /^\+[0-9\s.-]{4,}$/;
  return telephoneRegex.test(str);
}

export function checkIfOnlySpaces(str: string) {
  return /^\s*$/.test(str);
}

// checks edge case for native language answer
export const PickListDefault = ({
  savedValue,
  questionMapping,
}: IPicklistDefault) => {
  const hasDefault = savedValue !== '';
  const notEnglish = savedValue !== 'English';

  if (questionMapping === 'primaryLanguage' && hasDefault && notEnglish) {
    return 'false';
  }

  if (typeof savedValue === 'boolean') {
    return String(savedValue);
  }

  return savedValue;
};

// checks if string can be JSON parsed
export function isValidJSON(jsonString: string) {
  try {
    JSON.parse(jsonString);
    return true;
  } catch (error) {
    return false;
  }
}

export function checkFieldValidity(field?: string) {
  return field && !checkIfOnlySpaces(field) ? true : false;
}

export function isString(input: any) {
  return typeof input === 'string';
}

export function isSameValue(item: any, isSelected: any) {
  if (isString(item) && isString(isSelected)) {
    return item.toLowerCase() === isSelected.toLowerCase();
  }

  return item === isSelected;
}

export function checkEmailValidity({
  input,
  userEmail,
}: ICheckEmailValidity) {
  const matchEmailPattern =
    /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(input);

  const sameAsUserEmail = isSameValue(input, userEmail);

  return {
    message:
      matchEmailPattern && sameAsUserEmail
        ? 'invalid'
        : `${matchEmailPattern && !sameAsUserEmail}`,
    valid: matchEmailPattern && !sameAsUserEmail,
  };
}

export function stringifyBoolean(input: string) {
  return JSON.stringify(JSON.parse(input));
}

export function DependantValueDefault(
  dependentOn: string,
  dependantValue: string
) {
  if (dependentOn === 'program') {
    return dependantValue?.toLowerCase();
  }

  return dependantValue;
}

export function validateNumericalInput(input: string) {
  const validDigits = /^(?!0+$)[0-9]+$/.test(input);
  return validDigits || input === '0';
}

export function stringToInteger(input: any) {
  if (typeof input === 'string') {
    const strippedString = input.replace(/,/g, '');
    const parsedInteger = parseInt(strippedString, 10);

    if (!isNaN(parsedInteger)) {
      return parsedInteger;
    }
  }
  return input;
}


export function checkValidAddressState(state?: string) {
  return state && state.length > 2 ? true : undefined
}