import { useEffect, useState } from 'react';
import { LookUpInput } from '../LookUpInput';
import { IAddressUI } from './Address.types';
import { checkFieldValidity, checkIfOnlySpaces, checkValidAddressState } from '../QuestionInputHelpers';
import { IEvent } from '../../QuestionForm/questionForm.types';
import { StyledAddressWrapper } from './address.styles';
import { InputField } from 'hult-toolkit';

export const AddressUI = ({ defaultValue, handleChange }: IAddressUI) => {
  const [addressObj, setAddressObj] = useState(defaultValue);
  const [validCountry, setValidCountry] = useState(false);

  const validState = checkValidAddressState(addressObj?.addressState) && checkFieldValidity(addressObj?.addressState)

  useEffect(() => {
    if (addressObj) {
      const mandatoryFieldsAndValues = Object.entries(addressObj)
        .filter(entry => entry[0] !== 'address2')

      const checkValidity = mandatoryFieldsAndValues.every(ent => {
        if (ent[0] === 'addressState') {
          return validState
        }

        return !checkIfOnlySpaces(ent[1]);
      });

      handleChange({
        value: addressObj,
        valid: checkValidity && validCountry,
      });
    }
  }, [addressObj]);

  return (
    <StyledAddressWrapper>
      <LookUpInput
        label="Country/Region"
        defaultVal={defaultValue?.country}
        datasource="country-lookup"
        handleChange={(e: IEvent) => {
          setValidCountry(e.valid);
          setAddressObj({
            ...addressObj,
            country: e.value,
            countryName: e.name
          });
        }}
      />
      <InputField
        name="address1"
        type="text"
        label="Address Line 1"
        default_value={defaultValue?.address1 || ''}
        min_length={3}
        required
        onChange={e => {

          setAddressObj({
            ...addressObj,
            address1: e.value,
          });
        }}
      />
      <InputField
        type="text"
        name="addres2"
        label="Address Line 2 (optional)"
        default_value={defaultValue?.address2 || ''}
        onChange={e => {
          setAddressObj({
            ...addressObj,
            address2: e.value,
          });
        }}
      />
      <InputField
        type="text"
        name="city"
        label="City"
        default_value={defaultValue?.city || ''}
        required
        min_length={2}
        onChange={e => {
          setAddressObj({
            ...addressObj,
            city: e.value,
          })
        }}
      />
      <InputField
        type="text"
        name="postalCode"
        label="Post/Zip Code"
        min_length={2}
        default_value={defaultValue?.postalCode || ''}
        required
        onChange={e => {
          setAddressObj({
            ...addressObj,
            postalCode: e.value,
          })
        }}
      />
      <InputField
        name="state"
        type="text"
        label="State/County/Province"
        default_value={defaultValue?.addressState || ''}
        min_length={2}
        required
        onChange={e => {
          setAddressObj({
            ...addressObj,
            addressState: e.value,
          })
        }}
      />
    </StyledAddressWrapper>
  );
};
