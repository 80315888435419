import { NavigateFunction } from 'react-router-dom';
import { IHideShowQuestion, ISbQuestion } from '../../data/types';
import {
  ICheckRefetchQueries,
  ICheckTermsAcceptance,
  IDependencyMatch,
  IObjectKey,
  IQuestionPayloadRender,
  IQuestionProgramPayloadRender,
  IReplaceVariablesInQuestions,
} from './steps.types';
import { ISbStoryData } from '@storyblok/react';

export function sessionStoreValue(key: string) {

  const sessionStorageArr = ['program', 'campus', 'programStartTerm']

  return sessionStorageArr.includes(key)
}

export async function checkTermsAcceptance({
  dispatch,
  stepName,
  questionMapping,
  guid,
}: ICheckTermsAcceptance) {
  if (stepName === 'program' && questionMapping === 'program') {
    dispatch({
      type: 'uncomplete-step',
      payload: {
        guid,
        step: 'termsAndConditions',
      },
    });
  }
}

export function extractVariable(str: string) {
  const regex = /\{\{([^{}]+)\}\}/;
  const match = regex.exec(str);

  return match ? match[1] : null;
}

export function checkHideShowCriteria({
  dependent_value,
  value,
  dependent_type,
  dependant_condition,
}: IHideShowQuestion) {
  const arr = dependent_value?.toLowerCase()?.split(', ');

  const inputVal = typeof value === 'string' ? value?.toLowerCase() : value
  const dependentValFound = value && arr?.includes(inputVal);
  const booleanDepFound = JSON.stringify(value) === dependent_value;

  const matchingCriteria =
    typeof value === 'boolean' ? booleanDepFound : dependentValFound;

  if (dependent_type === 'show') {
    if (dependant_condition === 'neq') {
      return !matchingCriteria;
    }
    if (dependant_condition === 'eq') {
      return matchingCriteria;
    }
  }

  if (dependent_type === 'hide' && matchingCriteria) {
    if (dependant_condition === 'eq') {
      return false;
    }

    if (dependant_condition === 'neq') {
      return true;
    }
  }

  return true;
}

export function checkRefetchQueries({
  step_type,
  nextIndex,
  questions,
}: ICheckRefetchQueries) {
  const isMultiRecord = step_type === 'multi-record';
  const lastQuestion = nextIndex === questions.length;

  return isMultiRecord && lastQuestion
    ? ['GetApplicationMultiStep']
    : ['GetQuestionV2'];
}

export function replaceVariablesInQuestions({
  string,
  variableObj,
}: IReplaceVariablesInQuestions) {
  const sentenceVariable = extractVariable(string);

  if (sentenceVariable === 'school') {
    return string.replace(
      /\{\{([^{}]+)\}\}/g,
      JSON.parse(variableObj?.school).schoolName
    );
  }

  return string.replace(/\{\{([^{}]+)\}\}/g, (match, variable) => {
    return variableObj[variable] || match;
  });
}

export function leaveStep(
  navigate: NavigateFunction,
  questionQuery: IObjectKey
) {
  if (questionQuery.hasOwnProperty('editing')) {
    navigate({
      pathname: '/question',
      search: `step=${questionQuery?.step}`,
    });
  } else {
    navigate('/');
  }
}

export function DependencyMatch({
  dependent_on,
  dependent_type,
  dependent_value,
  dependant_condition,
  stateObj
}: IDependencyMatch) {

  const newStateVal = stateObj?.[dependent_on];

  const depVal = () => {
    if (typeof newStateVal === 'boolean') {
      return JSON.stringify(newStateVal)
    }
    return newStateVal
  }

  const includedVal = dependent_value.split(",").includes(depVal())

  const equalsConditionsMet = depVal() === dependent_value || includedVal;
  const notEqualsConditionsMet = depVal() !== dependent_value || !includedVal;

  const isHide = dependent_type === 'hide'
  const isShow = dependent_type === 'show'
  const isEq = dependant_condition === 'eq'
  const isNeq = dependant_condition === 'neq'

  if (isShow) {
    if (isEq) return equalsConditionsMet

    if (isNeq) return notEqualsConditionsMet
  }

  if (isHide) {
    if (isEq) return equalsConditionsMet ? false : true;
    if (isNeq) return notEqualsConditionsMet ? false : true;
  }

  return true
}

export function updateQuestions(questions: ISbStoryData<ISbQuestion>[], newState: any) {
  if (!questions?.length) {
    return []
  }

  const filteredQuestions = questions.filter((question: any) => {
    const {
      dependent_on,
      dependent_type,
      dependent_value,
      dependant_condition,
      second_dependent_condition,
      second_dependent_on,
      second_dependent_type,
      second_dependent_value,
    } = question.content;

    const hasDependencies = [
      dependent_on,
      dependent_type,
      dependent_value,
      dependant_condition
    ].every(str => str?.length)

    const secondDependencies = [
      second_dependent_on,
      second_dependent_type,
      second_dependent_value,
      second_dependent_condition
    ].every(str => str?.length)

    if (hasDependencies) {

      const firstDependentMatch = DependencyMatch({
        dependent_on,
        dependent_type,
        dependent_value,
        dependant_condition,
        stateObj: newState
      })

      if (secondDependencies) {
        const secondDependentMatch = DependencyMatch({
          dependent_on: second_dependent_on,
          dependent_type: second_dependent_type,
          dependent_value: second_dependent_value,
          dependant_condition: second_dependent_condition,
          stateObj: newState
        })

        return firstDependentMatch && secondDependentMatch
      }

      return firstDependentMatch
    }

    return true
  });

  return filteredQuestions
}

export const renderStepName = (step_name: string, questionQuery: any) => {
  if (questionQuery?.editing) {
    return questionQuery.editing.replace(/_\d+$/, '')
  }

  return step_name
}

export function getSavedValue(savedValues: any, questionMapping: string) {

  if (questionMapping === 'address') {
    return {
      address1: savedValues?.address1 ?? '',
      address2: savedValues?.address2 ?? '',
      city: savedValues?.city ?? '',
      country: savedValues?.country ?? '',
      postalCode: savedValues?.postalCode ?? '',
      addressState: savedValues?.addressState ?? '',
    }
  }

  if (questionMapping === 'billingAddress') {
    return {
      address1: savedValues?.billingLine1 ?? '',
      address2: savedValues?.billingLine2 ?? '',
      city: savedValues?.billingCity ?? '',
      country: savedValues?.billingCountry ?? '',
      postalCode: savedValues?.billingPostalCode ?? '',
      addressState: savedValues?.billingState ?? '',
    }
  }

  if (typeof savedValues[questionMapping] === 'boolean') {
    return JSON.stringify(savedValues[questionMapping])
  }

  return savedValues?.[questionMapping] || ''
}

export function questionPayloadRender({
  step_name,
  questions,
  savedValues,
  stepCategory,
  savedSession,
  isMultiStep = false
}: IQuestionPayloadRender) {

  const params = new URLSearchParams(location.search);

  const visibleQuestions = updateQuestions(questions, savedValues)

  const defaultFirstQuestion = visibleQuestions[0].slug
  const questionsOrder = visibleQuestions?.map((question: ISbStoryData<ISbQuestion>) => question?.slug)
  const questionMappingList = visibleQuestions?.map((question: ISbStoryData<ISbQuestion>) => question?.content?.question_mapping)
  const allQuestionMappingsList = questions?.map((question: ISbStoryData<ISbQuestion>) => question?.content?.question_mapping)
  const allQuestionMappings = Array.from(new Set(allQuestionMappingsList));

  const questionSlug = () => {
    if (step_name === 'program') {
      return defaultFirstQuestion
    }

    return params.get('q') ?? defaultFirstQuestion
  }

  const questionIndex = questionsOrder.indexOf(questionSlug())

  const firstQuestion = questionIndex === 0
  const lastQuestion = questionsOrder.length === 1 || questionIndex === questionsOrder.length - 1

  const current = visibleQuestions.find((obj: ISbStoryData<ISbQuestion>) => obj.slug === questionSlug())
  const questionMapping = current?.content?.question_mapping || ''

  const savedValue = getSavedValue(savedValues, questionMapping)

  return {
    stepName: step_name,
    stepCategory: isMultiStep ? stepCategory : '',
    allQuestionMappings,
    questions,
    questionMappingList,
    questionsOrder,
    questionSlug: questionSlug(),
    questionMapping,
    visibleQuestions,
    questionIndex,
    firstQuestion,
    lastQuestion,
    savedValues,
    savedSession,
    multiStepMode: isMultiStep,
    stepComplete: false,
    submitObj: {
      qMapping: questionMapping,
      savedValue,
      value: '',
      name: '',
      updated: false,
      valid: false
    }
  }
}

export function questionProgramPayloadRender({ programData, sessionData, questions }: IQuestionProgramPayloadRender) {

  const dispatchPayload = questionPayloadRender({
    isMultiStep: false,
    step_name: 'program',
    questions,
    savedValues: programData,
  })

  const savedSession: any = {}

  dispatchPayload.questionMappingList.forEach((qMap) => {
    if (qMap) {
      savedSession[qMap] = sessionData[qMap]
    }
  })

  return {
    ...dispatchPayload,
    savedSession
  }
}

export function checkComplete(questions: ISbStoryData<ISbQuestion>[], record: Record<string, any>) {

  if (questions?.length === 0 || Object.keys(record).length === 0) {
    return false;
  }

  const visibleQuestions = updateQuestions(questions, record)
  const requiredQuestions = visibleQuestions.filter((question: ISbStoryData<ISbQuestion>) => question?.content?.required)
  const allRequiredMapping = requiredQuestions.map((q) => q?.content?.question_mapping)
  
  return allRequiredMapping.every((mapping) => record[mapping as string] !== null && record[mapping as string] !== undefined)

}