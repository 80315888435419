import { AlertSa, LookupField, SelectField } from "hult-toolkit";
import { useState, useEffect } from "react";
import { useSettingsContext } from "../../../context/SettingsContext";
import { ILookUpObj } from "../../../data/types";
import { getDatasourceEntries } from "../../../services/storyblok";
import { variantMapping } from "../../../utils/variantMapping";
import { LoadingInput } from "../TextInput/TextInput.helpers";
import { ILookUpInput } from "./LookUp.types";
import { useStepContext } from "../../../context/StepContext";
import { useQuestionContext } from "../../../context/QuestionContext";

export const LookUpInput = ({
  datasource,
  handleChange,
  defaultVal,
  label
}: ILookUpInput) => {

  const { stepState } = useStepContext()
  const { questionState } = useQuestionContext()
  const { questionMapping } = questionState

  const inputVal = defaultVal || (stepState[questionMapping] ?? '')

  const [dsOptions, setDsOptions] = useState<ILookUpObj[] | undefined>([]);
  const [selectedOption, setSelectedOption] = useState<ILookUpObj | undefined>();

  const [submitObj, setSubmitObj] = useState({
    value: inputVal,
    valid: false,
    name: ''
  })

  const [optionsLoaded, setOptionsLoaded] = useState<boolean>(false);
  const { errorMessage } = useSettingsContext();

  useEffect(() => {
    if (datasource) {
      setOptionsLoaded(false);

      getDatasourceEntries(datasource)
        .then(data => {

          // Map and sort the options alphabetically by name
          const resData = data
            .map(({ label, value }) => ({
              value,
              name: label
            }))
            .sort((a, b) => a.name.localeCompare(b.name)); // Sort alphabetically

          const resDefault = resData.find(
            ({ value, name }: ILookUpObj) =>
              value === inputVal || name === inputVal
          );

          setSelectedOption(resDefault ?? undefined);
          setDsOptions(resData);
          setOptionsLoaded(true);
        })
        .catch(error => {
          console.error(error);
          const storyblokError = errorMessage?.desc?.content[0].content[0].text;
          return (
            <>
              {errorMessage && (
                <AlertSa
                  desc={storyblokError}
                  icon={variantMapping[errorMessage.variant].icon}
                  icon_color={variantMapping[errorMessage?.variant].icon_color}
                  title={errorMessage?.title || ''}
                />
              )}
            </>
          );
        });
    }
  }, [datasource, questionMapping]);

  useEffect(() => {
    handleChange({
      value: submitObj?.value,
      valid: submitObj?.valid,
      name: submitObj?.name,
    })
  }, [submitObj])

  if (!optionsLoaded) {
    return <LoadingInput loadingButton={false} />;
  }

  const formattedFieldOptions = dsOptions?.map((arr) => {
    return {
      name: arr.name,
      value: arr.value
    }
  })

  return (
    <SelectField
      name={""}
      required
      label={label}
      placeholder=""
      default_value={selectedOption?.value}
      show_validation={true}
      options={formattedFieldOptions || []}
      onChange={(selectedOption) => {
        setSubmitObj({
          value: selectedOption?.value || '',
          valid: selectedOption?.valid,
          name: selectedOption?.name
        })
      }}
    />
  );
};