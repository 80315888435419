import { useEffect, useState } from 'react';
import { AddressUI } from './AddressUI';
import { IEvent, IQuestionProps } from '../../QuestionForm/questionForm.types';
import { NewDatasourceFetcher } from '../../../services/storyblok';
import { useStepContext } from '../../../context/StepContext';

export const AddressInput = ({
  handleChange,
}: IQuestionProps) => {

  const { stepState } = useStepContext()

  const addressRecord = {
    address1: stepState?.address1 ?? '',
    address2: stepState?.address2 ?? '',
    city: stepState?.city ?? '',
    country: stepState?.country ?? '',
    postalCode: stepState?.postalCode ?? '',
    addressState: stepState?.addressState ?? '',
  }

  const billingRecord = {
    address1: stepState?.billingLine1 ?? '',
    address2: stepState?.billingLine2 ?? '',
    city: stepState?.billingCity ?? '',
    country: stepState?.billingCountry ?? '',
    postalCode: stepState?.billingPostalCode ?? '',
    addressState: stepState?.billingState ?? '',
  }

  const savedRecord = () => {
    if (stepState?.step === 'billing') {
      return billingRecord
    };

    return addressRecord
  }

  const [addressObj, setAddressObj] = useState(savedRecord());
  const [addressValid, setAddressValid] = useState(savedRecord() !== null);
  const { objects } = NewDatasourceFetcher("country-lookup")

  useEffect(() => {
    handleChange({
      value: addressObj,
      valid: addressValid,
    });
  }, [addressObj]);

  const countryObjects = objects?.map((obj) => {
    obj.name = obj.label
    return {
      name: obj.label,
      value: obj.value
    }
  })

  return (
    <AddressUI
      countryOptions={countryObjects}
      defaultValue={savedRecord()}
      handleChange={({ value, valid }: IEvent) => {
        setAddressObj(value);
        setAddressValid(valid);
      }}
    />
  );
};
